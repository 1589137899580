// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import SearchBar from '../../components/sidebar/SearchBar';
// import Sidebar from '../../components/sidebar/Sidebar';

// const AllEmployeeAttendance = ({ handleLogout, username }) => {
//     const [isLoading, setIsLoading] = useState(false);
//     const [employeesDetails, setEmployeesDetails] = useState([]);
//     const [date, setDate] = useState('');
//     const [error, setError] = useState('');
//     const [attendanceStatus, setAttendanceStatus] = useState({}); // Object to track attendance for each employee
//     const [overtimeHours, setOvertimeHours] = useState({}); // Object to track overtime hours for each employee

//     useEffect(() => {
//         fetchEmployeesDetails();
//     }, []);


//     useEffect(() => {
//         if (date) {
//             fetchAttendanceData(date); // Ensure department is passed correctly
//         }
//     }, [date]);

//     const fetchEmployeesDetails = async () => {
//         try {
//             const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/all_employess_attendance`);
//             const employeeData = response.data;

//             if (Array.isArray(employeeData) && employeeData.length > 0) {
//                 setEmployeesDetails(employeeData);
//                 setAttendanceStatus(
//                     employeeData.reduce((acc, employee) => {
//                         acc[employee.id] = ''; // Initialize attendance status for each employee
//                         return acc;
//                     }, {})
//                 );
//                 setOvertimeHours(
//                     employeeData.reduce((acc, employee) => {
//                         acc[employee.id] = ''; // Initialize overtime hours for each employee
//                         return acc;
//                     }, {})
//                 );
//             } else {
//                 setEmployeesDetails([]);
//             }

//             console.log("Employee Details:", employeeData);
//         } catch (error) {
//             console.error('Error fetching employees:', error);
//         }
//     };

//     // Fetch attendance data based on employee_id and date
//     const fetchAttendanceData = async (date) => {
//         try {
//             const attendanceData = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employeeattendance`, {
//                 params: {
//                     date: date
//                 }
//             });
//             const updatedAttendanceStatus = {};
//             const updatedOvertimeHours = {};

//             if (Array.isArray(attendanceData.data)) {
//                 attendanceData.data.forEach((attendance) => {
//                     updatedAttendanceStatus[attendance.employee_id] = attendance.status || '';
//                     updatedOvertimeHours[attendance.employee_id] = attendance.overtimeHours || '';
//                 });

//                 setAttendanceStatus(updatedAttendanceStatus);
//                 setOvertimeHours(updatedOvertimeHours);
//             }
//         } catch (error) {
//             console.error('Error fetching attendance data:', error);
//         }
//     };

//     const handleAttendanceChange = (employeeId, status) => {
//         setAttendanceStatus((prevState) => ({
//             ...prevState,
//             [employeeId]: status
//         }));
//     };

//     const handleOvertimeChange = (employeeId, hours) => {
//         setOvertimeHours((prevState) => ({
//             ...prevState,
//             [employeeId]: hours
//         }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         if (!date) {
//             setError('Please select a department and date.');
//             return;
//         }
//         setError('');
//         setIsLoading(true);

//         try {
//             const attendanceData = Object.keys(attendanceStatus).map((employeeId) => ({
//                 employeeId: employeeId,
//                 employeeCode: employeesDetails.find((emp) => emp.id === parseInt(employeeId)).employeeCode,
//                 date,
//                 status: attendanceStatus[employeeId],
//                 overtimeHours: attendanceStatus[employeeId] === 'overtime' ? overtimeHours[employeeId] : 0,
//                 employeeCode: employeesDetails.find((emp) => emp.id === parseInt(employeeId)).employeeCode,
//             }));

//             await axios.post(`${process.env.REACT_APP_LOCAL_URL}/api/bulkattendance`, attendanceData);
//             toast
//             .success('Attendance recorded successfully');
//         } catch (error) {
//             console.error('Error saving attendance:', error);
//             toast.error('Failed to record attendance');
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     return (
//         <div className='d-flex w-100 h-100 bg-white '>
//             <Sidebar />
//             <div className='w-100'>
//                 <SearchBar username={username} handleLogout={handleLogout} />
//                 <div className="container-fluid">
//                     <ToastContainer />
//                     <div className="row">
//                         <form onSubmit={handleSubmit} className="form-inne p-0 rounded" encType="multipart/form-data" acceptCharset="utf-8">
//                             {error && <div className="alert alert-danger">{error}</div>}
//                             <div className="col-xl-12 p-0">
//                                 <div className="card shadow mb-4">
//                                     <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
//                                         <h6 className="m-0 font-weight-bold text-primary">
//                                         All Employees Attendance
//                                         </h6>
//                                         <div className="d-flex gap-2">
//                                             <div className='d-flex align-items-center gap-2'>
//                                                 <label htmlFor="date" className="m-0">Date:</label>
//                                                 <input type="date" className="form-control" id="date" value={date} onChange={(e) => setDate(e.target.value)} />
//                                             </div>
//                                         </div>
//                                     </div>
//                                     <div className="card-body">
//                                         <div style={{ maxHeight: "calc(100vh - 140px)", overflowY: "auto", overflowX: "hidden" }}>
//                                             {employeesDetails.map((employee, item) => (
//                                                 <div key={employee.id} className="form-group col-md-12 p-2 bg-light border d-flex align-items-center justify-content-between rounded">
//                                                     <div className="form-group col-md-4 m-0 p-0 d-flex align-items-start ">
//                                                         <div className="d-flex align-items-center gap-2">
//                                                             <div>
//                                                                 <p style={{ fontSize: "14px" }} className="m-0 ">
//                                                                     <span className='text-black fw-bolder'>SL:</span> {item + 1 || "N/A"}
//                                                                 </p>
//                                                                 {/* <p style={{ fontSize: "14px" }} className="m-0 ">
//                                                                     <span className='text-black fw-bolder'>Employee Code:</span> {employee.employeeCode || "N/A"}
//                                                                 </p> */}
//                                                                 <p style={{ fontSize: "14px" }} className="m-0 ">
//                                                                     <span className='text-black fw-bolder'>Employee Name:</span> {employee.employeeName || "N/A"} ({employee.employeeCode || "N/A"})
//                                                                 </p>
//                                                                 <p style={{ fontSize: "14px" }} className="m-0 ">
//                                                                     <span className='text-black fw-bolder'>Department:</span> {employee.departmentName || "N/A"}
//                                                                 </p>
//                                                                 <p style={{ fontSize: "14px" }} className="m-0 ">
//                                                                     <span className='text-black fw-bolder'>Designation:</span> {employee.positionName || "N/A"}
//                                                                 </p>
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div className="form-group col-md-7 m-0">
//                                                         <div className="d-flex align-items-center justify-content-around">
//                                                             <div className="d-flex flex-column align-items-center justify-content-center gap-1">
//                                                                 <div className="d-flex gap-1">
//                                                                     <button type="button" style={{ fontSize: "14px" }} className={`border border-4 btn btn-${attendanceStatus[employee.id] === 'present' ? 'success' : 'default'}`} onClick={() => handleAttendanceChange(employee.id, 'present')}>Present</button>
//                                                                     <button type="button" style={{ fontSize: "14px" }} className={`border border-4 btn btn-${attendanceStatus[employee.id] === 'paid leave' ? 'warning' : 'default'}`} onClick={() => handleAttendanceChange(employee.id, 'paid leave')}>Paid Leave</button>
//                                                                     <button type="button" style={{ fontSize: "14px" }} className={`border border-4 btn btn-${attendanceStatus[employee.id] === 'overtime' ? 'primary' : 'default'}`} onClick={() => handleAttendanceChange(employee.id, 'overtime')}>Overtime</button>
//                                                                     <button type="button" style={{ fontSize: "14px" }} className={`border border-4 btn btn-${attendanceStatus[employee.id] === 'weekly off' ? 'warning' : 'default'}`} onClick={() => handleAttendanceChange(employee.id, 'weekly off')}>Weekly Off</button>
//                                                                 </div>
//                                                                 <div className="d-flex gap-1">
//                                                                     <button type="button" style={{ fontSize: "14px" }} className={`border border-4 btn btn-${attendanceStatus[employee.id] === 'absent' ? 'danger' : 'default'}`} onClick={() => handleAttendanceChange(employee.id, 'absent')}>Absent</button>
//                                                                     <button type="button" style={{ fontSize: "14px" }} className={`border border-4 btn btn-${attendanceStatus[employee.id] === 'half day' ? 'info' : 'default'}`} onClick={() => handleAttendanceChange(employee.id, 'half day')}>Half Day</button>
//                                                                     <button type="button" style={{ fontSize: "14px" }} className={`border border-4 btn btn-${attendanceStatus[employee.id] === 'unpaid leave' ? 'warning' : 'default'}`} onClick={() => handleAttendanceChange(employee.id, 'unpaid leave')}>Unpaid Leave</button>
//                                                                 </div>
//                                                             </div>
//                                                             {attendanceStatus[employee.id] === 'overtime' && (
//                                                                 <div className="mt-2">
//                                                                     <label style={{ fontSize: "14px" }} htmlFor={`overtimeHours-${employee.id}`}>Overtime Hours:</label>
//                                                                     <select
//                                                                         id={`overtimeHours-${employee.id}`}
//                                                                         className="form-control"
//                                                                         style={{ fontSize: "14px" }}
//                                                                         value={overtimeHours[employee.id] || ''}
//                                                                         onChange={(e) => handleOvertimeChange(employee.id, e.target.value)}
//                                                                     >
//                                                                         <option value="">Select hours</option>
//                                                                         {Array.from({ length: 8 }, (_, i) => i + 1).map((hour) => (
//                                                                             <option key={hour} value={hour}>
//                                                                                 {hour} {hour === 1 ? 'Hour' : 'Hours'}
//                                                                             </option>
//                                                                         ))}
//                                                                     </select>
//                                                                 </div>
//                                                             )}
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             ))}
//                                         </div>
//                                         <div className='text-center'>
//                                             <button type="submit" className="btn btn-primary" disabled={isLoading}>
//                                                 {isLoading ? 'Loading...' : 'Submit'}
//                                             </button>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AllEmployeeAttendance;





















































import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchBar from '../../components/sidebar/SearchBar';
import Sidebar from '../../components/sidebar/Sidebar';

const AllEmployeeAttendance = ({ handleLogout, username }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [employeesDetails, setEmployeesDetails] = useState([]);
    const [date, setDate] = useState('');
    const [error, setError] = useState('');
    const [attendanceStatus, setAttendanceStatus] = useState({}); // Object to track attendance for each employee
    const [overtimeHours, setOvertimeHours] = useState({}); // Object to track overtime hours for each employee

    useEffect(() => {
        fetchEmployeesDetails();
    }, []);


    useEffect(() => {
        if (date) {
            fetchAttendanceData(date); // Ensure department is passed correctly
        }
    }, [date]);

    const fetchEmployeesDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/all_employess_attendance`);
            const employeeData = response.data;

            if (Array.isArray(employeeData) && employeeData.length > 0) {
                setEmployeesDetails(employeeData);
                setAttendanceStatus(
                    employeeData.reduce((acc, employee) => {
                        acc[employee.id] = ''; // Initialize attendance status for each employee
                        return acc;
                    }, {})
                );
                setOvertimeHours(
                    employeeData.reduce((acc, employee) => {
                        acc[employee.id] = ''; // Initialize overtime hours for each employee
                        return acc;
                    }, {})
                );
            } else {
                setEmployeesDetails([]);
            }

            console.log("Employee Details:", employeeData);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    // Fetch attendance data based on employee_id and date
    const fetchAttendanceData = async (date) => {
        try {
            const attendanceData = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/employeeattendance`, {
                params: {
                    date: date
                }
            });
            const updatedAttendanceStatus = {};
            const updatedOvertimeHours = {};

            if (Array.isArray(attendanceData.data)) {
                attendanceData.data.forEach((attendance) => {
                    updatedAttendanceStatus[attendance.employee_id] = attendance.status || '';
                    updatedOvertimeHours[attendance.employee_id] = attendance.overtimeHours || '';
                });

                setAttendanceStatus(updatedAttendanceStatus);
                setOvertimeHours(updatedOvertimeHours);
            }
        } catch (error) {
            console.error('Error fetching attendance data:', error);
        }
    };

    const handleAttendanceChange = (employeeId, status) => {
        setAttendanceStatus((prevState) => ({
            ...prevState,
            [employeeId]: status
        }));
    };

    const handleOvertimeChange = (employeeId, hours) => {
        setOvertimeHours((prevState) => ({
            ...prevState,
            [employeeId]: hours
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!date) {
            toast.error('Please select a date.');
            // setError('Please select a date.');
            return;
        }
        setError('');
        setIsLoading(true);

        try {
            const attendanceData = Object.keys(attendanceStatus).map((employeeId) => ({
                employeeId: employeeId,
                employeeCode: employeesDetails.find((emp) => emp.id === parseInt(employeeId)).employeeCode,
                date,
                status: attendanceStatus[employeeId],
                overtimeHours: attendanceStatus[employeeId] === 'overtime' ? overtimeHours[employeeId] : 0,
                employeeCode: employeesDetails.find((emp) => emp.id === parseInt(employeeId)).employeeCode,
            }));

            await axios.post(`${process.env.REACT_APP_LOCAL_URL}/api/bulkattendance`, attendanceData);
            toast
                .success('Attendance recorded successfully');
        } catch (error) {
            console.error('Error saving attendance:', error);
            toast.error('Failed to record attendance');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='d-flex w-100 h-100 bg-white '>
            <Sidebar />
            <div className='w-100'>
                <SearchBar username={username} handleLogout={handleLogout} />
                <div className="container-fluid">
                    <ToastContainer />
                    <div className="row">
                        <form onSubmit={handleSubmit} className="form-inne p-0 rounded " encType="multipart/form-data" acceptCharset="utf-8">

                            <div className="col-xl-12 m-0 p-0">
                                <div className="card shadow">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">
                                            All Employees Attendance
                                        </h6>
                                        <div className="d-flex gap-2">
                                            <div className='d-flex align-items-center gap-2'>
                                                <label htmlFor="date" className="m-0">Date:</label>
                                                <input type="date" className="form-control form-control-sm" id="date" value={date} onChange={(e) => setDate(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* {error && <div className="alert alert-danger">{error}</div>} */}
                                    <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                                        {/* {error && <div className="alert alert-danger m-0">{error}</div>} */}
                                        <div className="card-body p-1">
                                            {employeesDetails.map((employee, item) => (
                                                <div key={employee.id} className="form-group col-md-12 p-2 bg-light border d-flex align-items-center justify-content-between rounded">
                                                    <div className="form-group col-md-4 m-0 p-0 d-flex align-items-start ">
                                                        <div className="d-flex align-items-center gap-2">
                                                            <div>
                                                                <p style={{ fontSize: "14px" }} className="m-0 ">
                                                                    <span className='text-black fw-bolder'>SL:</span> {item + 1 || "N/A"}
                                                                </p>
                                                                {/* <p style={{ fontSize: "14px" }} className="m-0 ">
                                                                    <span className='text-black fw-bolder'>Employee Code:</span> {employee.employeeCode || "N/A"}
                                                                </p> */}
                                                                <p style={{ fontSize: "14px" }} className="m-0 ">
                                                                    <span className='text-black fw-bolder'>Employee Name:</span> {employee.employeeName || "N/A"} ({employee.employeeCode || "N/A"})
                                                                </p>
                                                                <p style={{ fontSize: "14px" }} className="m-0 ">
                                                                    <span className='text-black fw-bolder'>Department:</span> {employee.departmentName || "N/A"}
                                                                </p>
                                                                <p style={{ fontSize: "14px" }} className="m-0 ">
                                                                    <span className='text-black fw-bolder'>Designation:</span> {employee.positionName || "N/A"}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-8 m-0">
                                                        <div className="d-flex align-items-center justify-content-around">
                                                            <div className="d-flex flex-column align-items-center justify-content-center gap-1">
                                                                <div className="d-flex gap-1">
                                                                    <button type="button" style={{ fontSize: "13px" }} className={`border border-4 btn btn-${attendanceStatus[employee.id] === 'present' ? 'success' : 'default'}`} onClick={() => handleAttendanceChange(employee.id, 'present')}>Present</button>
                                                                    <button type="button" style={{ fontSize: "13px" }} className={`border border-4 btn btn-${attendanceStatus[employee.id] === 'paid leave' ? 'warning' : 'default'}`} onClick={() => handleAttendanceChange(employee.id, 'paid leave')}>Paid Leave</button>
                                                                    <button type="button" style={{ fontSize: "13px" }} className={`border border-4 btn btn-${attendanceStatus[employee.id] === 'overtime' ? 'primary' : 'default'}`} onClick={() => handleAttendanceChange(employee.id, 'overtime')}>Overtime</button>
                                                                    <button type="button" style={{ fontSize: "13px" }} className={`border border-4 btn btn-${attendanceStatus[employee.id] === 'weekly off' ? 'warning' : 'default'}`} onClick={() => handleAttendanceChange(employee.id, 'weekly off')}>Weekly Off</button>

                                                                    <button type="button" style={{ fontSize: "13px" }} className={`border border-4 btn btn-${attendanceStatus[employee.id] === 'absent' ? 'danger' : 'default'}`} onClick={() => handleAttendanceChange(employee.id, 'absent')}>Absent</button>
                                                                    <button type="button" style={{ fontSize: "13px" }} className={`border border-4 btn btn-${attendanceStatus[employee.id] === 'half day' ? 'info' : 'default'}`} onClick={() => handleAttendanceChange(employee.id, 'half day')}>Half Day</button>
                                                                    <button type="button" style={{ fontSize: "13px" }} className={`border border-4 btn btn-${attendanceStatus[employee.id] === 'unpaid leave' ? 'warning' : 'default'}`} onClick={() => handleAttendanceChange(employee.id, 'unpaid leave')}>Unpaid Leave</button>
                                                                </div>
                                                            </div>
                                                            {attendanceStatus[employee.id] === 'overtime' && (
                                                                <div className="">
                                                                    {/* <label style={{ fontSize: "13px" }} htmlFor={`overtimeHours-${employee.id}`}>Overtime Hours:</label> */}
                                                                    <select
                                                                        id={`overtimeHours-${employee.id}`}
                                                                        className="form-control"
                                                                        style={{ fontSize: "13px" }}
                                                                        value={overtimeHours[employee.id] || ''}
                                                                        onChange={(e) => handleOvertimeChange(employee.id, e.target.value)}
                                                                    >
                                                                        <option value="">Select hours</option>
                                                                        {Array.from({ length: 8 }, (_, i) => i + 1).map((hour) => (
                                                                            <option key={hour} value={hour}>
                                                                                {hour} {hour === 1 ? 'Hour' : 'Hours'}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='text-center border rounded py-2'>
                                        <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                            {isLoading ? 'Loading...' : 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllEmployeeAttendance;
